import React, { useState } from 'react';
import useProductPage from './useProductPage';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import Iconify from '../../components/iconify';
import CommonTableToolbar from 'src/components/CommonTableToolbar';
import Scrollbar from 'src/components/scrollbar';
import CommonTableHead from 'src/components/CommonTableHead';
import Paper from 'src/theme/overrides/Paper';
import AlertModal from 'src/components/AlertModal';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';

const TABLE_HEAD = [{ id: 'name', label: 'Name', alignRight: false }, { id: '' }];

const ProductPage = () => {
  const enableSelect = false;
  const {
    data,
    categories,
    isValidating,
    isError,
    productState,
    showModal,
    showAddProduct,
    formik,
    loading,
    selectedTab,
    filteredData,
    selectedCategory,
    isAddingCategory,
    setSelectedCategory,
    handleAddCategory,
    setIsAddingCategory,
    handleDeleteConfirm,
    handleModalClose,
    handleDelete,
    handleAddProduct,
    closeAddProductModal,
    handleTabChangeWrapper,
  } = useProductPage();


  return (
    <>
      <Helmet>
        <title>Product</title>
      </Helmet>

      <Container>
        <Card>
          {categories?.length > 0 && (
            <Tabs value={selectedTab} onChange={handleTabChangeWrapper} variant="scrollable" scrollButtons="auto">
              {categories.map((category, index) => (
                <Tab key={index} label={category} />
              ))}
            </Tabs>
          )}

          <Box sx={{ p: 3 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {categories?.length > 0 && (
                <Button
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  className="bg-primary"
                  onClick={handleAddProduct}
                >
                  Add {selectedCategory}
                </Button>
              )}
              <Button
                variant="outlined"
                startIcon={<Iconify icon="eva:plus-fill" />}
                // className="bg-primary"
                onClick={handleAddCategory}
              >
                Add Category
              </Button>
            </Stack>
          </Box>

          {/* <CommonTableToolbar numSelected={0} filterName={productState.searchValue} onSearch={handleSearch} /> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {isValidating ? (
                <TableRow>
                  <TableCell className="w-full">
                    <Typography align="center" sx={{ my: 2 }}>
                      Loading...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <Table>
                  <CommonTableHead headLabel={TABLE_HEAD} rowCount={data?.length ?? 0} enableSelect={enableSelect} />
                  <TableBody>
                    <>
                      {filteredData.length > 0 ? (
                        filteredData?.map((row) => (
                          <TableRow hover key={row._id} tabIndex={-1} role="checkbox" selected={false}>
                            {enableSelect && (
                              <TableCell padding="checkbox">
                                <Checkbox checked={false} onChange={() => {}} />
                              </TableCell>
                            )}
                            <TableCell component="th" scope="row" padding="normal">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {row.name ?? ''}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="right">
                              <Tooltip title={`Delete ${row.name}`}>
                                <IconButton size="large" color="inherit" onClick={() => handleDeleteConfirm(row._id)}>
                                  <Iconify icon="eva:trash-2-fill" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Box padding={3}>No data found</Box>
                      )}
                    </>
                  </TableBody>
                  {/* {(isError || data?.length === 0) && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper sx={{ textAlign: 'center' }}>
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>
                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{productState.searchValue}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )} */}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
          <AlertModal
            show={showModal}
            continueText="Delete"
            dialogTitle="Confirm Delete"
            dialogContent="Are you sure you want to delete this item?"
            handleClose={handleModalClose}
            handleContinue={handleDelete}
          />
        </Card>
        <Drawer
          open={showAddProduct}
          anchor="right"
          onClose={() => {
            closeAddProductModal();
            setIsAddingCategory(false);
          }}
        >
          <Box sx={{ width: 400, height: '100vh', backgroundColor: 'white' }}>
            <Stack spacing={3} className="m-5 h-[90%]">
              <Typography variant="h4">Add Product</Typography>
              <TextField
                name="Name"
                label="Name"
                {...formik.getFieldProps('name')}
                error={formik.touched.name && formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
              />
              {isAddingCategory && (
                <TextField
                  name="category"
                  label="Category"
                  {...formik.getFieldProps('category')}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  error={formik.touched.category && formik.errors.category}
                  helperText={formik.touched.category && formik.errors.category}
                />
              )}
              <LoadingButton
                loading={loading}
                fullWidth
                size="large"
                type="submit"
                className="bg-primary !mt-auto"
                variant="contained"
                onClick={formik.handleSubmit}
              >
                Add
              </LoadingButton>
            </Stack>
          </Box>
        </Drawer>
      </Container>
    </>
  );
};

export default ProductPage;
