export const setWithExpiry = (key, value, expirationHours) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + expirationHours * 60 * 60 * 1000, // Convert minutes to milliseconds
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    // Item has expired, remove it from localStorage
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};
