import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Drawer,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Chip,
  Switch,
} from '@mui/material';
import Iconify from '../../components/iconify';
import CommonTableToolbar from 'src/components/CommonTableToolbar';
import Scrollbar from 'src/components/scrollbar';
import CommonTableHead from 'src/components/CommonTableHead';
import Paper from 'src/theme/overrides/Paper';
import AlertModal from 'src/components/AlertModal';
import ColorToggleButton from './ToggleComponent';
import useOrderPage from './useProductPage';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SearchComponent } from 'src/utils/components/SearchComponent';
import Loader from 'src/utils/components/loader';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'count', label: 'Count', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

const TABLE_BULK_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'count', label: 'Count', alignRight: false },
  { id: '' },
];

// const TOGGLE_HEAD = [
//   { value: 'single', label: 'single' },
//   { value: 'bulk', label: 'Bulk' },
// ];

// const STATUS_TOGGLE_HEAD = [
//   { value: '0', label: 'Pending' },
//   { value: '1', label: 'Fullfilled' },
// ];

const TotalOrders = () => {
  const enableSelect = false;
  const {
    data,
    isLoading,
    isValidating,
    isError,
    orderState,
    showModal,
    handleDeleteConfirm,
    handleModalClose,
    handleSearch,
    handleDelete,
    handleToggle,
    handleStatus,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSwitch,
    handleStartDate,
    handleEndDate,
  } = useOrderPage();

  return (
    <>
      <Helmet>
        <title> TOTAL ORDERS </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Total Orders
          </Typography>
        </Stack>
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={1}>
            {/* <Stack direction="row" justifyContent="space-between" mr={2}>
              <ColorToggleButton
                data={TOGGLE_HEAD}
                handleToggle={handleToggle}
                mr={2}
                style={{ marginRight: '30px' }}
              />
              <ColorToggleButton data={STATUS_TOGGLE_HEAD} handleToggle={handleStatus} style={{ marginRight: '0px' }} />
            </Stack> */}
            {/* <Stack direction="row" justifyContent="space-between" mr={2} mt={1}>
              <div style={{ marginRight: '5px' }}>
                <DatePicker
                  label="Start Date"
                  value={orderState?.startDate}
                  onChange={(newValue) => handleStartDate(newValue)}
                />
              </div>
              <DatePicker
                label="End Date"
                value={orderState?.endDate}
                onChange={(newValue) => handleEndDate(newValue)}
              />
            </Stack> */}
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="end" mt={1} mb={2} mr={2}>
            <SearchComponent handleSearch={handleSearch} />
          </Stack>
          {isValidating || isLoading ? <Loader /> : ''}
          <Scrollbar>
            {data?.data?.data?.length != 0 ? (
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  {/* <CommonTableHead
                    headLabel={orderState?.currentFilter == 'single' ? TABLE_HEAD : TABLE_BULK_HEAD}
                    rowCount={data?.data?.total}
                    enableSelect={enableSelect}
                  /> */}
                  <TableBody>
                    {data?.data?.data?.map((row) => {
                      return (
                        <TableRow hover key={row?._id} tabIndex={-1} role="checkbox" selected={false}>
                          {enableSelect && (
                            <TableCell padding="checkbox">
                              <Checkbox checked={false} onChange={() => {}} />
                            </TableCell>
                          )}

                          <TableCell component="th" scope="row" padding="normal">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {row?.name ?? ''}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="normal">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {row?.count ?? ''}
                              </Typography>
                            </Stack>
                          </TableCell>
                          {orderState?.currentFilter == 'single' ? (
                            <TableCell component="th" scope="row" padding="normal">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  <Chip
                                    label={row?.status ?? ''}
                                    color={row?.status == 0 ? 'warning' : 'primary'}
                                    size="small"
                                  />
                                </Typography>
                              </Stack>
                            </TableCell>
                          ) : (
                            ''
                          )}

                          {/* <TableCell align="right">
                            <Switch
                              checked={row?.status == 0 ? false : true}
                              onChange={(e) => handleChangeSwitch(e, row)}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {/* {(isError || (data?.data?.data && data?.data?.data?.length === 0)) && (
                           <TableBody>
                             <TableRow>
                               <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                 <Paper
                                   sx={{
                                     textAlign: 'center',
                                   }}
                                 >
                                   <Typography variant="h6" paragraph>
                                     Not found
                                   </Typography>
       
                                   <Typography variant="body2">
                                     No results found for &nbsp;
                                     <strong>&quot;{orderState.searchValue}&quot;</strong>.
                                     <br /> Try checking for typos or using complete words.
                                   </Typography>
                                 </Paper>
                               </TableCell>
                             </TableRow>
                           </TableBody>
                         )} */}
                </Table>
              </TableContainer>
            ) : (
              'No Data Found'
            )}
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.data?.total}
            rowsPerPage={orderState.per_page}
            page={orderState.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <AlertModal
            show={showModal}
            continueText="Delete"
            dialogTitle="Confirm Delete"
            dialogContent="Are you sure you want to delete this item?"
            handleClose={handleModalClose}
            handleContinue={handleDelete}
          />
        </Card>
      </Container>
    </>
  );
};

export default TotalOrders;
