import { useRecoilState } from 'recoil';
import { ordersListState } from 'src/recoil/ordersAtom';
import { useSwrStatic } from 'src/utils/hooks/swr';
import { CreatItem, DeleteItem, DeleteOrder, ItemList, updateOrder } from './api';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const useOrderPage = () => {
  const [orderState, setOrderState] = useRecoilState(ordersListState);
  const [options, setOptions] = useState([]);
  const [itemoptions, setitemOptions] = useState([]);
  const [filteredShops, setFilteredShops] = useState([]); // New state
  const { data, isValidating, isError, mutate } = useSwrStatic('/orders/list?status=0&page=1&per_page=20');
  const { data: categories, mutate: categoryMutate } = useSwrStatic('/items/list-categories');
  const { data: items } = useSwrStatic('/items/list');
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [showAddOrder, setShowAddOrder] = useState(false);
  const [showEditOrder, setShowEditOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);

  const [selectedItem, setSelectedItem] = useState('');
  const [itemCount, setItemCount] = useState(1);

  useEffect(() => {
    categories &&
      setOptions(
        categories?.data?.categories?.map((value) => ({
          label: value,
          value: value,
        }))
      );
  }, [categories]);

  useEffect(() => {
    items && setitemOptions(items?.data?.data);
  }, [items]);

  useEffect(() => {
    categoryMutate();
  }, []);

  const handleShopNameChange = (event) => {
    const inputValue = event.target.value;

    const filtered = data?.data?.data.filter((order) =>
      order.shopName.toLowerCase().includes(inputValue?.toLowerCase())
    );

    setFilteredShops(filtered);
    formik.setFieldValue('shopName', inputValue);
  };

  const handleShopSelect = (shop) => {
    formik.setValues({
      shopName: shop.shopName,
      shopId: shop.shopId,
      contactNumber: shop.contactNumber,
      salesman: shop.salesman,
      vehicleNumber: shop.vehicleNumber,
      orderItems: formik.values.orderItems, // Retain the current order items
    });

    setFilteredShops([]); // Clear the dropdown after selection
  };

  const addFields = () => {
    let { orderItems } = formik?.values;
    orderItems = [...orderItems, { id: '', count: '', category: '' }];
    formik.setFieldValue('orderItems', orderItems);
  };

  const removeFields = (index) => {
    const data = formik?.values?.orderItems?.filter((item, itemIndex) => itemIndex !== index);
    formik.setFieldValue('orderItems', data);
  };

  const formik = useFormik({
    initialValues: {
      contactNumber: '',
      shopName: '',
      shopId: '',
      salesman: '',
      vehicleNumber:'',
      orderItems: [{ id: '', count: '', unit: '' }],
    },
    validate: (values) => {
      let errors = {};
      if (!values.contactNumber) {
        errors.contactNumber = 'Customer Name is required';
      }
      if (!values.shopName) {
        errors.shopName = 'Shop Name is required';
      }
      if (!values.shopId) {
        errors.shopId = 'Shop Id is required';
      }
      if (!values.salesman) {
        errors.salesman = 'Sales man name is required';
      }
      if (!values.vehicleNumber) {
        errors.salesman = 'vehicle Number is required';
      }
      return errors;
    },
    onSubmit: (values) => {
      setLoading(true);
      if (isUpdating) {
        updateOrder(values).then((r) => {
          setLoading(false);
          if (r.status === 200 || r.status === 201) {
            mutate();
            toast.success('Order updated successfully');
            setShowAddOrder(false);
            formik.resetForm();
          } else if (r.status === 400) {
            formik.setFieldError('name', r?.data?.message ?? 'Item already exists!');
          } else {
            toast("Oops! Couldn't add item");
          }
        });
      } else {
        CreatItem(values).then((r) => {
          setLoading(false);
          if (r.status === 200 || r.status === 201) {
            mutate();
            toast.success('Order created successfully');
            setShowAddOrder(false);
            formik.resetForm();
          } else if (r.status === 400) {
            formik.setFieldError('name', r?.data?.message ?? 'Item already exists!');
          } else {
            toast("Oops! Couldn't add item");
          }
        });
      }
    },
  });

  const handleSearch = (e) => {
    setOrderState((state) => ({ ...state, searchValue: e.target.value }));
  };

  const handleChangePage = () => {};
  const handleChangeRowsPerPage = () => {};

  const handleDeleteConfirm = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setDeleteId('');
  };

  const handleDelete = () => {
    DeleteOrder(deleteId).then((r) => console.log(r));
    mutate();
    setShowModal(false);
  };

  const handleAddOrder = () => {
    setisUpdating(false);
    setShowAddOrder(true);
  };

  const handleEditOrder = (orderData) => {
    setisUpdating(true);
    formik.setValues({
      _id: orderData._id,
      orderStatus: orderData.orderStatus,
      shopId: orderData.shopId,
      salesman:orderData.salesman,
      contactNumber: orderData.contactNumber,
      shopName: orderData.shopName,
      vehicleNumber: orderData.vehicleNumber,
      orderItems: orderData.orderItems.map((item) => ({
        id: item.itemId?._id,
        name:item.itemId?.name,
        count: item.count,
        unit: item.unit,
      })),
    });
    setShowAddOrder(true);
  };

  const closeAddOrderModal = () => {
    setShowAddOrder(false);
    formik.resetForm();
  };
  const closeEditOrderModal = () => {
    setShowEditOrder(false);
  };

  return {
    items: items?.data?.data,
    data,
    isValidating,
    isError,
    orderState,
    showModal,
    showAddOrder,
    formik,
    loading,
    selectedItem,
    itemCount,
    options,
    itemoptions,
    filteredShops, // Return the filtered shops state
    isUpdating,
    setSelectedItem,
    removeFields,
    setItemCount,
    handleDeleteConfirm,
    handleModalClose,
    handleSearch,
    handleDelete,
    handleChangePage,
    handleAddOrder,
    handleEditOrder,
    closeAddOrderModal,
    handleChangeRowsPerPage,
    addFields,
    handleShopNameChange, // Return the handleShopNameChange function
    handleShopSelect, // Return the handleShopSelect function
  };
};

export default useOrderPage;
