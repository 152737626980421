import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSwrStatic } from 'src/utils/hooks/swr';

const useOrderDetail = () => {
  const { id } = useParams();
  const [openInvoice, setOpenInvoice] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const { data, isValidating, isError, mutate } = useSwrStatic(`/orders/get-order/${id}`);
useEffect(() => {
mutate()
}, [])


  useEffect(() => {
    setInvoiceData({
      id: data?.data?.data?._id,
      invoice_no: '201906-28',
      balance: '$2,283.74',
      company: data?.data?.data?.shopName,
      name: data?.data?.data?.customerName,
      due_date: '2019-10-12',
      items: data?.data?.data?.orderItems?.map((item, index) => {
        return {
          sno: item?.itemId?._id,
          category: item?.category,
          product: item?.itemId?.name,
          qty: item?.count,
          rate: 0,
        };
      }),
    });
    // mutate()
  }, [data]);


  const handleOpenInvoice = () => {
    setOpenInvoice(true);
  };

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const handleChangeRate = (itemData, e) => {
    setInvoiceData({
      ...invoiceData,
      items: invoiceData.items.map((item, i) => {

        if (item?.sno == itemData?.sno) {
          return {
            ...item,
            rate: e.target.value,
          };
        } else {
          return item;
        }
      }),
    });
  };

  return {
    id,
    data: data?.data?.data ?? {},
    invoiceData,
    isValidating,
    isError,
    openInvoice,
    handleOpenInvoice,
    handleCloseInvoice,
    handleChangeRate,
  };
};

export default useOrderDetail;
