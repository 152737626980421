import { getAxiosInstance } from 'src/api';
import { useSwrStatic } from 'src/utils/hooks/swr';

export const DeleteOrder = async (id) => {
  try {
    const api = await getAxiosInstance();
    const response = await api.delete(`/orders/delete/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};
export const CreatItem = async (params) => {
  try {
    const api = await getAxiosInstance();
    const response = await api.post(`/orders/create`, params);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateOrder = async (params) => {
  try {
    const api = await getAxiosInstance();
    const response = await api.post(`/orders/update`, params);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const ItemList =  () => {
 const  {data,isError,isLoading,isValidating,mutate}= useSwrStatic(`/items/list`);

};