import React from 'react';
import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import InvoiceTitle from './Blocks/InvoiceTitle';
import InvoiceNo from './Blocks/InvoiceNo';
import BillTo from './Blocks/BillTo';
import Table from './Table';
import InvoiceThankYouMsg from './Table/InvoiceThankYouMsg';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const Invoice = ({ invoice }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* <Image style={styles.logo} src={logo} /> */}
        <InvoiceTitle title="Invoice" />
        <InvoiceNo invoice={invoice} />
        <BillTo invoice={invoice} />
        <Table invoice={invoice} />
        <InvoiceThankYouMsg />
      </Page>
    </Document>
  );
};

export default Invoice;
