import { useEffect, useState } from 'react';
import CommonTableToolbar from 'src/components/CommonTableToolbar';
import { useDebounce } from '../../../hooks/useDebounce';

export const SearchComponent = ({ handleSearch }) => {
  const [text, setText] = useState('');

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const debouncedSearchTerm = useDebounce(text, 700);

  useEffect(() => {
    handleSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return <CommonTableToolbar numSelected={0} filterName={text} onSearch={handleChange} handleSearch={handleSearch} />;
};
