import { Navigate } from 'react-router';

const ProtectRoute = ({ children }) => {
  const token = localStorage.getItem('USER_ACCESS_TOKEN');
  if (!token) {
    return <Navigate to={'/login'} />;
  }

  return children;
};

export default ProtectRoute;
