import React from 'react';
import ReactDOM from 'react-dom';
import invoiceData from './data';
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import Invoice from './Invoice';

const styles = StyleSheet.create({
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
    zIndex: 9998, // Ensure backdrop is behind the modal content
  },
  view: {
    position: 'fixed',
    zIndex: 9999,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '50px',
    width: '80%', // Modified width
    height: '80%', // Modified height
    overflow: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    // background: '#FF5D5B',
    // border: '4px solid #CF544D',
    // background: '#ffffff',
    // border: '1px solid #ccc',
    borderRadius: '50%',
    padding: '8px', // Increased padding for larger clickable area
    cursor: 'pointer',
    zIndex: 10000, // Ensure close button is above other content
    display: 'flex', // Ensuring inner content is centered
    justifyContent: 'center', // Centering content horizontally
    alignItems: 'center', // Centering content vertically
    width: '30px', // Adjusting width and height for button size
    height: '30px',
    fontSize: '18px', // Increasing font size for better visibility
    fontWeight: 'bold', // Making the 'X' bold
    color: '#333', // Changing color for better contrast
  },
});

const PDFInvoice = ({ data, handleClose }) => {
  return ReactDOM.createPortal(
    <>
      <div style={styles.backdrop} onClick={handleClose}></div>
      <div style={styles.view}>
        <div style={styles.closeButton} onClick={handleClose}>
          X
        </div>
        <PDFViewer width="100%" height="100%" className="app">
          <Invoice invoice={data} />
        </PDFViewer>
      </div>
    </>,
    document.body
  );
};

export default PDFInvoice;
