import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductPage from './pages/ProductPage';
import OrdersPage from './pages/OrdersPage';
import TotalOrders from './pages/TotalOrders';
import ProtectRoute from './utils/components/ProtectRoute';
import OrderDetailPage from './pages/OrderDetailPage';
import InvoicePage from './pages/InvoicePage';
import OrderViewPage from './pages/OrderViewPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <ProtectRoute>
          <DashboardLayout />
        </ProtectRoute>
      ),
      children: [
        { element: <Navigate to="orders" />, index: true },
        { path: 'orders', element: <OrdersPage /> },
        { path: 'invoice', element: <InvoicePage /> },
        { path: 'order/:id', element: <OrderDetailPage /> },
        { path: 'order-view/:id', element: <OrderViewPage /> },
        { path: 'products', element: <ProductPage /> },
        { path: 'total-orders', element: <TotalOrders /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="dashboard/orders" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
