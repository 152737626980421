import dayjs from 'dayjs';
import { getDate } from '../hooks/getDate';

const { atom } = require('recoil');

export const orderItemState = atom({
  key: 'orderItemState',
  default: {
    startDate: null,
    endDate: null,
    searchValue: '',
    page: 0,
    per_page: 10,
    currentFilter: 'bulk',
    status: 0,
  },
});
