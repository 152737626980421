import { useRecoilState } from 'recoil';
import { productsListState } from 'src/recoil/productsAtom';
import { useSwrStatic } from 'src/utils/hooks/swr';
import { DeleteItem, UpdateItem } from './api';
import { useState } from 'react';
import { orderItemState } from 'src/recoil/orderAtom';
import toast, { Toaster } from 'react-hot-toast';

const useOrderPage = () => {
  const [orderState, setOrderState] = useRecoilState(orderItemState);
  let slug = orderState?.currentFilter == 'single' ? 'list' : 'bulk-list';
  let startDate = orderState.startDate ? orderState.startDate : '';
  let endDate = orderState.endDate ? orderState.endDate : '';

  const { data, isLoading, isValidating, isError, mutate } = useSwrStatic(
    `/order-items/${slug}?status=${orderState?.status}&page=${orderState?.page}&per_page=${orderState?.per_page}&search=${orderState?.searchValue}&start_date=${startDate}&end_date=${endDate}`,
    { revalidate: true }
  );

  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [showAddOrders, setShowAddOrders] = useState(false);

  const handleSearch = (e) => {
    setOrderState((state) => ({ ...state, searchValue: e }));
  };

  const handleStartDate = (e) => {
    let actualDate = e.$d;
    const year = actualDate.getFullYear();
    const month = String(actualDate.getMonth() + 1).padStart(2, '0');
    const day = String(actualDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    setOrderState((state) => ({ ...state, startDate: formattedDate }));
  };
  const handleEndDate = (e) => {
    let actualDate = e.$d;
    const year = actualDate.getFullYear();
    const month = String(actualDate.getMonth() + 1).padStart(2, '0');
    const day = String(actualDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    setOrderState((state) => ({ ...state, endDate: formattedDate }));
  };

  const handleChangePage = (event, newPage) => {
    setOrderState((state) => ({ ...state, page: newPage }));
  };
  const handleChangeRowsPerPage = (e) => {
    setOrderState((state) => ({ ...state, per_page: e.target.value }));
  };

  const handleDeleteConfirm = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setDeleteId('');
  };

  const handleDelete = () => {
    DeleteItem(deleteId).then((r) => console.log(r));
    setShowModal(false);
  };

  const handleAddOrders = () => {
    setShowAddOrders(true);
  };

  const handleCloseOrders = () => {
    setShowAddOrders(false);
  };

  const handleToggle = (value) => {
    setOrderState((state) => ({ ...state, currentFilter: value }));
  };

  const handleStatus = (value) => {
    setOrderState((state) => ({ ...state, status: value }));
  };

  const handleChangeSwitch = (e, data) => {
    let status_msg = data?.status == 0 ? 'fullfilled' : 'pending';

    let obj = {
      name: data?.name,
      from_status: data?.status,
      to_status: data?.status == 0 ? 1 : 0,
    };
    UpdateItem(obj).then((res) => {
      toast.success(`moved ${data.name} to ${status_msg} state`);
      mutate();
    });
  };

  return {
    data,
    isLoading,
    isValidating,
    isError,
    orderState,
    showModal,
    showAddOrders,
    handleToggle,
    handleDeleteConfirm,
    handleModalClose,
    handleSearch,
    handleDelete,
    handleChangePage,
    handleAddOrders,
    handleCloseOrders,
    handleStatus,
    handleChangeRowsPerPage,
    handleChangeSwitch,
    handleStartDate,
    handleEndDate,
  };
};

export default useOrderPage;
