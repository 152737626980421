import axios from 'axios';
import { getWithExpiry } from 'src/utils/functions/localStorage';

const BASE_URL = process.env.REACT_APP_BASEURL;

let token = '';

export const getAxiosInstance = async () => {
  try {
    token = getWithExpiry('USER_ACCESS_TOKEN');
  } finally {
    const instance = axios.create({
      baseURL: BASE_URL,

      headers: {
        Accept: 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    instance.interceptors.request.use(
      function (response) {
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    return instance;
  }
};
