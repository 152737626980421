import { getAxiosInstance } from 'src/api';

export const DeleteItem = async (id) => {
  try {
    const api = await getAxiosInstance();
    const response = await api.delete(`/items/delete/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const UpdateItem = async (params) => {
  try {
    const api = await getAxiosInstance();
    const response = await api.post(`order-items/update`, params);
    return response;
  } catch (error) {
    return error.response;
  }
};
