import { getAxiosInstance } from 'src/api';

export const CreatItem = async (params) => {
  try {
    const api = await getAxiosInstance();
    const response = await api.post(`/items/create`, params);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const DeleteItem = async (id) => {
  try {
    const api = await getAxiosInstance();
    const response = await api.delete(`/items/delete/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};
