import { useRecoilState } from 'recoil';
import { productsListState } from 'src/recoil/productsAtom';
import { useSwrStatic } from 'src/utils/hooks/swr';
import { CreatItem, DeleteItem } from './api';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';

const useProductPage = () => {
  const [productState, setProductState] = useRecoilState(productsListState);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isAddingCategory, setIsAddingCategory] = useState(false);

  const { data, isValidating, isError, mutate } = useSwrStatic(
    // `/items/list?page=1&per_page=10&search=${productState.searchValue}`
    `/items/list`
  );


  const { data: categoryData, isValidating: categoryIsValidating,mutate:categoryDataMutate } = useSwrStatic('/items/list-categories', {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (data && categoryData) {
      const filteredData = data?.data?.data?.filter(
        (row) => row.category === categoryData?.data?.categories[selectedTab]
      );
      setFilteredData(filteredData);
    }
  }, [selectedTab,data,categoryData]);

  useEffect(() => {
    if (categoryData)
      if(selectedCategory === '') {
      setSelectedCategory(categoryData?.data?.categories[0]);
    }else{
      setSelectedCategory(categoryData?.data?.categories[selectedTab])
    }
  }, [categoryData]);

  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      rate: '',
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = 'Name is required';
      }
      return errors;
    },
    onSubmit: (values) => {
      setLoading(true);
      CreatItem({ ...values, category: selectedCategory }).then((response) => {
        setLoading(false);
        if (response.status === 200) {
          mutate();
          categoryDataMutate()
          toast.success('Item created successfully');
          // setSelectedCategory(categoryData?.data?.categories[0]);
          // setSelectedTab(0)
          setShowAddProduct(false);
          setIsAddingCategory(false)
          formik.resetForm();
          categoryDataMutate()
        } else if (response.status === 400) {
          formik.setFieldError('name', response.data.message ?? 'Item already exists!');
        } else {
          toast("Oops! Couldn't add item");
        }
      });
    },
  });

  // const handleSearch = (event) => {
  //   setProductState((state) => ({ ...state, searchValue: event.target.value }));
  // };

  const handleDeleteConfirm = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setDeleteId('');
  };

  const handleDelete = () => {
    DeleteItem(deleteId).then((response) => {
      if (response.status === 200) {

        mutate();
        categoryDataMutate()
      }
      setShowModal(false);
    });
  };

  const handleAddProduct = () => {
    setShowAddProduct(true);
  };

  const closeAddProductModal = () => {
    setShowAddProduct(false);
  };

  const handleTabChangeWrapper = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedCategory(categoryData?.data?.categories[newValue]);
  };

  const handleAddCategory = () => {
    setIsAddingCategory(true);
    handleAddProduct();
  };

  return {
    data: data?.data?.data,
    categories: categoryData?.data?.categories,
    isValidating,
    isError,
    productState,
    showModal,
    showAddProduct,
    formik,
    loading,
    selectedTab,
    filteredData,
    selectedCategory,
    isAddingCategory,
    setIsAddingCategory,
    handleAddCategory,
    setSelectedCategory,
    handleDeleteConfirm,
    handleModalClose,
    handleDelete,
    handleAddProduct,
    closeAddProductModal,
    handleTabChangeWrapper,
  };
};

export default useProductPage;
