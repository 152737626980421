import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Login } from './api';
import { setWithExpiry } from 'src/utils/functions/localStorage';
import { useState } from 'react';

const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validate: (values) => {
      const errors = {};
      if (!values.username) {
        errors.username = 'Username cannot be empty';
      }
      if (!values.password) {
        errors.password = 'Password cannot be empty';
      }
      return errors;
    },
    onSubmit: (values) => {
      setLoading(true);
      Login(values).then((r) => {
        setLoading(false);
        if (r.data.error === true) {
          formik.setFieldError('password', r.data.message);
        } else if (r.status === 200) {
          //expiry is in hours
          setWithExpiry('USER_ACCESS_TOKEN', r.data.token, 24 * 7);
          navigate('/dashboard/products');
        } else {
          formik.setFieldError('password', 'Oops an error occured');
        }
      });
    },
  });
  return { loading, formik };
};

export default useLogin;
