import { useRecoilState } from 'recoil';
import { ordersListState } from 'src/recoil/ordersAtom';
import { useSwrStatic } from 'src/utils/hooks/swr';
import { CreatItem, DeleteItem, DeleteOrder, ItemList, updateOrder } from './api';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const useInvoicePage = () => {
  const [orderState, setOrderState] = useRecoilState(ordersListState);
  const [options, setOptions] = useState([]);
  const [itemoptions, setitemOptions] = useState([]);
  const { data, isValidating, isError, mutate } = useSwrStatic('/orders/list?status=0&page=1&per_page=20');
  const { data: categories, mutate: categoryMutate } = useSwrStatic('/items/list-categories');
  const { data: items } = useSwrStatic('/items/list');
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [showAddOrder, setShowAddOrder] = useState(false);
  const [showEditOrder, setShowEditOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);

  const [selectedItem, setSelectedItem] = useState('');
  const [itemCount, setItemCount] = useState(1);

  useEffect(() => {
    categories &&
      setOptions(
        categories?.data?.categories?.map((value) => ({
          label: value,
          value: value,
        }))
      );
  }, [categories]);
  useEffect(() => {
    items && setitemOptions(items?.data?.data);
  }, [items]);

  useEffect(()=>{
    categoryMutate()
  },[])


  // useEffect(() => {
  //   setitemOptions(items?.data?.data.filter((value) => value.category == selectedItem));
  // }, [selectedItem]);

  const addFields = () => {
    let { orderItems } = formik?.values;
    orderItems = [...orderItems, { id: '', count: '', category: '' }];
    formik.setFieldValue('orderItems', orderItems);
  };

  const removeFields = (index) => {
    const data = formik?.values?.orderItems?.filter((item, itemIndex) => itemIndex !== index);
    formik.setFieldValue('orderItems', data);
  };

  const formik = useFormik({
    initialValues: {
      contactNumber: '',
      shopName: '',
      orderItems: [{ id: '', count: '', category: '' }],
    },
    validate: (values) => {
      let errors = {};
      if (!values.contactNumber) {
        errors.contactNumber = 'Customer Name is required';
      }
      if (!values.shopName) {
        errors.shopName = 'Shop Name is required';
      }
      return errors;
    },
    // validationSchema,

    onSubmit: (values) => {
      setLoading(true);
if(isUpdating){
  updateOrder(values).then((r) => {
    setLoading(false);
    if (r.status === 200 || r.status === 201) {
      mutate();
      toast.success('Order updated succesfully');
      setShowAddOrder(false);
      formik.resetForm();
    } else if (r.status === 400) {
      formik.setFieldError('name', r?.data?.message ?? 'Item already exsts!');
    } else {
      toast("Oops! Couldn't add item");
    }
  });

}else{

  CreatItem(values).then((r) => {
    setLoading(false);
    if (r.status === 200 || r.status === 201) {
      mutate();
      toast.success('Order created succesfully');
      setShowAddOrder(false);
      formik.resetForm();
    } else if (r.status === 400) {
      formik.setFieldError('name', r?.data?.message ?? 'Item already exsts!');
    } else {
      toast("Oops! Couldn't add item");
    }
  });
}
    },
  });

  const handleSearch = (e) => {
    setOrderState((state) => ({ ...state, searchValue: e.target.value }));
  };

  const handleChangePage = () => {};
  const handleChangeRowsPerPage = () => {};

  const handleDeleteConfirm = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setDeleteId('');
  };

  const handleDelete = () => {
    DeleteOrder(deleteId).then((r) => console.log(r));
    mutate();
    setShowModal(false);
  };

  const handleAddOrder = () => {
    setisUpdating(false)
    setShowAddOrder(true);
  };


  


  const handleEditOrder = (orderData) => {
    setisUpdating(true)

    formik.setValues({
      _id: orderData._id,
      orderStatus:orderData.orderStatus,
     customerName: orderData.customerName,
      shopName: orderData.shopName,
      orderItems: orderData.orderItems.map(item => ({
        id: item.itemId?._id,
        count: item.count,
        category: item.category,
      })),
    });
    setShowAddOrder(true);
  };

  const closeAddOrderModal = () => {
    setShowAddOrder(false);
    formik.resetForm()
  };
  const closeEditOrderModal = () => {
    setShowEditOrder(false);
  };



  return {
    items: items?.data?.data,
    data,
    isValidating,
    isError,
    orderState,
    showModal,
    showAddOrder,
    formik,
    loading,
    selectedItem,
    itemCount,
    options,
    itemoptions,isUpdating,
    setSelectedItem,
    removeFields,
    setItemCount,
    handleDeleteConfirm,
    handleModalClose,
    handleSearch,
    handleDelete,
    handleChangePage,
    handleAddOrder,
    handleEditOrder,
    closeAddOrderModal,
    handleChangeRowsPerPage,
    addFields,
  };
};

export default useInvoicePage;
