import React from 'react';
import useOrderDetail from './useOrderDetail';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Card,
  Checkbox,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import CommonTableHead from 'src/components/CommonTableHead';
import PDFInvoice from 'src/components/Invoice';

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  // { id: 'category', label: 'Category', alignRight: false },
  { id: 'count', label: 'Quantity (kgs)' },
  { id: 'rate', label: 'Rate' },
  { id: 'amount', label: 'Amount' },
];

const OrderDetailPage = () => {
  const enableSelect = false;
  const {
    data,
    invoiceData,
    isValidating,
    isError,
    openInvoice,
    handleOpenInvoice,
    handleCloseInvoice,
    handleChangeRate,
  } = useOrderDetail();

  return (
    <>
      {openInvoice && <PDFInvoice data={invoiceData} handleClose={handleCloseInvoice} />}
      <Helmet>
        <title> Orders </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {data?.shopName ?? ''}
          </Typography>
        </Stack>
        <Card>
          {/* <CommonTableToolbar numSelected={0} filterName={orderState.searchValue} onFilterName={handleSearch} /> */}
          <Scrollbar>
            {isValidating ? (
              <Typography variant="h4" gutterBottom>
                Loading
              </Typography>
            ) : (
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <CommonTableHead
                    // order={order}
                    // orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data?.data?.total}
                    // numSelected={0}
                    // onRequestSort={handleRequestSort}
                    // onSelectAllClick={handleSelectAllClick}
                    enableSelect={enableSelect}
                  />
                  <TableBody>
                    {isValidating ? (
                      <TableRow>
                        <TableCell className="w-full ">
                          <Typography align="center" sx={{ my: 2 }}>
                            Loading...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      invoiceData?.items?.map((row, index) => {
                        const serialNumber = index + 1;
                        return (
                          <TableRow hover key={row?._id} tabIndex={-1} role="checkbox" selected={false}>
                            {enableSelect && (
                              <TableCell padding="checkbox">
                                <Checkbox checked={false} onChange={() => {}} />
                              </TableCell>
                            )}
                            {<TableCell padding="normal">{serialNumber}</TableCell>}

                            <TableCell component="th" scope="row" padding="normal">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {row?.product ?? ''}
                                </Typography>
                              </Stack>
                            </TableCell>
                            {/* <TableCell component="th" scope="row" padding="normal">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {row?.category ?? ''}
                                </Typography>
                              </Stack>
                            </TableCell> */}
                            <TableCell component="th" scope="row" padding="normal">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {row?.qty ?? ''}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell component="th" scope="row" padding="normal">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <TextField
                                  name="rate"
                                  label="Rate"
                                  onChange={(e) => handleChangeRate(row, e)}
                                  // {...formik.getFieldProps('contactNumber')}
                                  // error={formik.touched.contactNumber && formik.errors.contactNumber}
                                  // helperText={formik.touched.contactNumber && formik.errors.contactNumber}
                                />
                              </Stack>
                            </TableCell>

                            <TableCell component="th" scope="row" padding="normal">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {(row?.qty * row?.rate).toFixed(2) ?? ''}
                                </Typography>
                              </Stack>
                            </TableCell>

                            {/* 
                            <TableCell align="right">
                              <Tooltip title={`edit ${row?.customerName}`}>
                                <IconButton size="large" color="inherit" onClick={() => handleEditOrder(row?._id)}>
                                  <Iconify icon="eva:edit-fill" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={`Delete ${row?.customerName}`}>
                                <IconButton size="large" color="inherit" onClick={() => handleDeleteConfirm(row?._id)}>
                                  <Iconify icon="eva:trash-2-fill" />
                                </IconButton>
                              </Tooltip>
                            </TableCell> */}
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                  {(isError || (data?.data?.data && data?.data?.data?.length === 0)) && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          {/* <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{productState?.searchValue}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper> */}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            )}
          </Scrollbar>
        </Card>
        <Button color="secondary" onClick={() => handleOpenInvoice()}>
          Print Invoice
        </Button>
      </Container>
    </>
  );
};

export default OrderDetailPage;
