import { useState } from 'react';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import useLogin from './useLogin';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const { loading, formik } = useLogin();

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="username"
          label="User Name"
          {...formik.getFieldProps('username')}
          error={formik.touched.username && formik.errors.username}
          helperText={formik.touched.username && formik.errors.username}
        />

        <TextField
          name="password"
          label="Password"
          {...formik.getFieldProps('password')}
          error={formik.touched.password && formik.errors.password}
          helperText={formik.touched.password && formik.errors.password}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      {/* 
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}

      <LoadingButton
        loading={loading}
        fullWidth
        size="large"
        type="submit"
        className="bg-primary mt-7"
        variant="contained"
        onClick={formik.handleSubmit}
      >
        Login
      </LoadingButton>
    </>
  );
}
