import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

const AlertModal = ({
  show,
  continueText = 'Continue',
  cancelText = 'Cancel',
  dialogContent = '',
  dialogTitle = '',
  handleClose = () => {},
  handleContinue = () => {},
}) => {
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{cancelText}</Button>
        <Button onClick={handleContinue} autoFocus>
          {continueText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
