const { atom } = require('recoil');

export const productsListState = atom({
  key: 'productsListState',
  default: {
    searchValue: '',
    page: 0,
    per_page: 10,
  },
});
