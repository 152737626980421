import { getAxiosInstance } from 'src/api';

export const Login = async (params) => {
  try {
    const api = await getAxiosInstance();
    const response = await api.post('/users/auth', params);
    return response;
  } catch (error) {
    return error.response;
  }
};
