import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Select from 'react-select';
import { components } from 'react-select';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  // Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Iconify from '../../components/iconify';
import Scrollbar from 'src/components/scrollbar';
import CommonTableHead from 'src/components/CommonTableHead';
import AlertModal from 'src/components/AlertModal';
import { LoadingButton } from '@mui/lab';
import useInvoicePage from './useInvoicePage';
import { useNavigate } from 'react-router-dom';

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'shop', label: 'Shop Name', alignRight: false },
  { id: 'orderCount', label: 'Order Count', alignRight: false },
  { id: 'orderStatus', label: 'Order Status', alignRight: false },
  { id: '' },
];

const TABLE_HEAD_ADD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'item', label: 'Item', alignRight: false },
  { id: 'count', label: 'Count', alignRight: false },
  { id: '' },
];

const InvoicePage = () => {
  const enableSelect = false;
  const {
    addFields,
    data,
    isValidating,
    isError,
    orderState,
    showModal,
    showAddOrder,
    formik,
    loading,
    options,
    itemoptions,
    selectedItem,
    isUpdating,
    setItemCount,
    setSelectedItem,
    handleDeleteConfirm,
    handleModalClose,
    handleSearch,
    handleDelete,
    handleChangePage,
    handleAddOrder,
    closeAddOrderModal,
    handleChangeRowsPerPage,
    handleEditOrder,
    removeFields,
  } = useInvoicePage();

  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title> Orders </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Invoice
          </Typography>
          {/* <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            className="bg-primary"
            onClick={handleAddOrder}
          >
            Add Order
          </Button> */}
        </Stack>
        <Card>
          <Scrollbar>
            {isValidating ? (
              <Typography variant="h4" gutterBottom>
                Loading
              </Typography>
            ) : (
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <CommonTableHead headLabel={TABLE_HEAD} rowCount={data?.data?.total} enableSelect={enableSelect} />
                  <TableBody>
                    {isValidating ? (
                      <TableRow>
                        <TableCell className="w-full ">
                          <Typography align="center" sx={{ my: 2 }}>
                            Loading...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      data?.data?.data?.map((row, index) => {
                        const serialNumber = index + 1;
                        return (
                          <TableRow hover key={row?._id} tabIndex={-1} role="checkbox" selected={false}>
                            {enableSelect && (
                              <TableCell padding="checkbox">
                                <Checkbox checked={false} onChange={() => {}} />
                              </TableCell>
                            )}

                            {
                              <TableCell onClick={() => navigate(`/dashboard/order/${row?._id}`)} padding="normal">
                                {' '}
                                {serialNumber}
                              </TableCell>
                            }

                            {/* <TableCell component="th" scope="row" padding="normal">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {row?.contactNumber ?? ''}
                                </Typography>
                              </Stack>
                            </TableCell> */}
                            <TableCell
                              onClick={() => navigate(`/dashboard/order/${row?._id}`)}
                              component="th"
                              scope="row"
                              padding="normal"
                            >
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {row?.shopName ?? ''}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell
                              onClick={() => navigate(`/dashboard/order/${row?._id}`)}
                              component="th"
                              scope="row"
                              padding="normal"
                            >
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {row?.orderItems.length}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell
                              onClick={() => navigate(`/dashboard/order/${row?._id}`)}
                              component="th"
                              scope="row"
                              padding="normal"
                            >
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {row?.orderStatus == 0 ? 'Not completed' : 'Completed'}
                                </Typography>
                              </Stack>
                            </TableCell>

                            {/* <TableCell align="right">
                              <Tooltip title={`edit ${row?.contactNumber}`}>
                                <IconButton size="large" color="inherit" onClick={() => handleEditOrder(row)}>
                                  <Iconify icon="eva:edit-fill" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={`Delete ${row?.contactNumber}`}>
                                <IconButton size="large" color="inherit" onClick={() => handleDeleteConfirm(row?._id)}>
                                  <Iconify icon="eva:trash-2-fill" />
                                </IconButton>
                              </Tooltip>
                            </TableCell> */}
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                  {(isError || (data?.data?.data && data?.data?.data?.length === 0)) && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          {/* <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{productState?.searchValue}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper> */}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            )}
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.data?.total}
            rowsPerPage={orderState.per_page}
            page={orderState.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <AlertModal
            show={showModal}
            continueText="Delete"
            dialogTitle="Confirm Delete"
            dialogContent="Are you sure you want to delete this item?"
            handleClose={handleModalClose}
            handleContinue={handleDelete}
          />
        </Card>
        <Drawer open={showAddOrder} anchor="right" onClose={closeAddOrderModal}>
          <Box
            sx={{
              width: 'auto',
              height: '100vh',
              backgroundColor: 'white',
            }}
          >
            <Stack spacing={3} className="m-5 h-[90%]">
              <Typography variant="h4">{isUpdating ? 'Update Order' : 'Add Order'}</Typography>
              <TextField
                name="Shop Name"
                label="Shop Name"
                {...formik.getFieldProps('shopName')}
                error={formik.touched.shopName && formik.errors.shopName}
                helperText={formik.touched.shopName && formik.errors.shopName}
              />
              <TextField
                name="Contact Number"
                label="Contact Number"
                {...formik.getFieldProps('contactNumber')}
                error={formik.touched.contactNumber && formik.errors.contactNumber}
                helperText={formik.touched.contactNumber && formik.errors.contactNumber}
              />

              <Typography variant="h5">Order Items</Typography>
              <div>
                <TableContainer sx={{ minWidth: 600 }}>
                  <Table>
                    <CommonTableHead
                      headLabel={TABLE_HEAD_ADD}
                      rowCount={data?.data?.total}
                      enableSelect={enableSelect}
                    />
                    <TableBody>
                      {isValidating ? (
                        <TableRow>
                          <TableCell className="w-full ">
                            <Typography align="center" sx={{ my: 2 }}>
                              Loading...
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        formik?.values?.orderItems?.map((row, index) => {
                          const itemArray = itemoptions?.filter((item) => item.category === row.category);

                          const items = itemArray?.map((item) => ({ value: item._id, label: item.name }));

                          const serialNumber = index + 1;
                          return (
                            <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={false}>
                              {enableSelect && (
                                <TableCell padding="checkbox">
                                  <Checkbox checked={false} onChange={() => {}} />
                                </TableCell>
                              )}

                              {<TableCell padding="checkbox"> {serialNumber}</TableCell>}

                              <TableCell component="th" scope="row" padding="normal">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Select
                                    placeholder="Category"
                                    isClearable
                                    options={options}
                                    value={options.find((item) => item.value === row.category)}
                                    onChange={(selectedOption) => {
                                      formik.setFieldValue(
                                        `orderItems[${index}].category`,
                                        selectedOption?.label || ''
                                      );
                                      // setSelectedItem(selectedOption.value);
                                    }}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                  {/* <Typography variant="subtitle2" noWrap>
                                  {row?.category ?? ''}
                                </Typography> */}
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" padding="normal">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Select
                                    placeholder="Item"
                                    isClearable
                                    options={items}
                                    // {...formik.getFieldProps(`orderItems[${index}].id`)}
                                    onChange={(selectedOption) => {
                                      formik.setFieldValue(`orderItems[${index}].id`, selectedOption?.value || '');
                                    }}
                                    value={items?.find((item) => item.value === row?.id || row?._id)}
                                  />
                                  {/* <Typography variant="subtitle2" noWrap>
                                  {row?.item ?? ''}
                                </Typography> */}
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" padding="normal">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <TextField
                                    // style={{height:"20px"}}
                                    name="itemCount"
                                    placeholder="Count"
                                    type="number"
                                    {...formik.getFieldProps(`orderItems[${index}].count`)}
                                    error={
                                      formik.touched.orderItems?.[index]?.count &&
                                      formik.errors.orderItems?.[index]?.count
                                    }
                                    helperText={
                                      formik.touched.orderItems?.[index]?.count &&
                                      formik.errors.orderItems?.[index]?.count
                                    }
                                  />
                                  {/* <Typography variant="subtitle2" noWrap>
                                  {row?.count}
                                </Typography> */}
                                </Stack>
                              </TableCell>
                              {/* <TableCell component="th" scope="row" padding="normal">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {row?.orderStatus == 0 ? 'Not completed' : 'Completed'}
                                </Typography>
                              </Stack>
                            </TableCell> */}

                              <TableCell align="right">
                                {/* <Tooltip title={`edit ${row?.contactNumber}`}>
                                <IconButton size="large" color="inherit" onClick={() => handleEditOrder(row)}>
                                  <Iconify icon="eva:edit-fill" />
                                </IconButton>
                              </Tooltip> */}
                                <Tooltip title={`Delete ${row?.contactNumber}`}>
                                  <IconButton size="large" color="inherit" onClick={() => removeFields(index)}>
                                    <Iconify icon="eva:trash-2-fill" />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                    {(isError || (data?.data?.data && data?.data?.data?.length === 0)) && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            {/* <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{productState?.searchValue}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper> */}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>

            
              </div>
              {/* {formik?.values?.orderItems?.map((value, index) => {
                const itemArray = itemoptions?.filter((item) => item.category === value.category);

                const items = itemArray?.map((item) => ({ value: item._id, label: item.name }));
               

                return (
                  <div key={index}>
                    <FormControl fullWidth>
                      <Select
                        placeholder="Category"
                        isClearable
                        options={options}
                        value={options.find((item) => item.value === value.category)}
                        onChange={(selectedOption) => {
                          formik.setFieldValue(`orderItems[${index}].category`, selectedOption?.label || '');
                          // setSelectedItem(selectedOption.value);
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                      <Select
                        placeholder="Item"
                        isClearable
                        options={items}
                        // {...formik.getFieldProps(`orderItems[${index}].id`)}
                        onChange={(selectedOption) => {

                          formik.setFieldValue(`orderItems[${index}].id`, selectedOption?.value || '');
                        }}
                        value={items?.find((item) => item.value === value?.id || value?._id)}
                      />
                      <TextField
                        style={{ marginBottom: '20px' }}
                        name="itemCount"
                        placeholder="Count"
                        type="number"
                        {...formik.getFieldProps(`orderItems[${index}].count`)}
                        error={formik.touched.orderItems?.[index]?.count && formik.errors.orderItems?.[index]?.count}
                        helperText={
                          formik.touched.orderItems?.[index]?.count && formik.errors.orderItems?.[index]?.count
                        }
                      />
                    </FormControl>

                    <Button className="bg-primary" variant="contained" onClick={() => removeFields(index)}>
                      Remove Item
                    </Button>
                  </div>
                );
              })} */}

              <Button style={{ marginBottom: '50px' }} className="bg-primary" variant="contained" onClick={addFields}>
                Add More Item
              </Button>

              <LoadingButton
                loading={loading}
                fullWidth
                size="large"
                type="submit"
                className="bg-primary !mt-auto"
                variant="contained"
                onClick={formik.handleSubmit}
              >
                Add
              </LoadingButton>
            </Stack>
          </Box>
        </Drawer>
      </Container>
    </>
  );
};

export default InvoicePage;
