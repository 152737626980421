import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Select from 'react-select';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Iconify from '../../components/iconify';
import Scrollbar from 'src/components/scrollbar';
import CommonTableHead from 'src/components/CommonTableHead';
import AlertModal from 'src/components/AlertModal';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import useOrderPage from './useOrderPage';
import { useNavigate } from 'react-router-dom';
// import CloseIcon from '@mui/icons-material/Close';

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'shop', label: 'Shop Name', alignRight: false },
  { id: 'shopId', label: 'Shop ID', alignRight: false },
  { id: 'orderCount', label: 'Order Count', alignRight: false },
  { id: '' },
];


const OrdersPage = () => {
  const enableSelect = false;
  const {
    addFields,
    data,
    isValidating,
    isError,
    orderState,
    showModal,
    showAddOrder,
    formik,
    loading,
    options,
    itemoptions,
    selectedItem,
    isUpdating,
    setItemCount,
    setSelectedItem,
    handleDeleteConfirm,
    handleModalClose,
    handleSearch,
    handleDelete,
    handleChangePage,
    handleAddOrder,
    closeAddOrderModal,
    handleChangeRowsPerPage,
    handleEditOrder,
    removeFields,
    filteredShops,
    handleShopNameChange,
    handleShopSelect,
  } = useOrderPage();

  const navigate = useNavigate();

  const formattedItems = itemoptions.map((item) => ({
    label: `${item.name} (${item.category})`,
    value: item._id,
  }));

  const unitOptions = [
    { value: 'box', label: 'Box' },
    { value: 'doz', label: 'Doz' },
    { value: 'vs', label: 'Piece' },
    { value: 'kg', label: 'Kg' },
    { value: 'bunch', label: 'Bunch' },
    { value: 'tray', label: 'Tray' },
    { value: 'packet', label: 'Packet' },
    { value: 'ctn', label: 'CTN' },
  ];

  const [selectedSalesman, setSelectedSalesman] = useState(null);
  const [selectedDate, setSelectedDate] = useState(''); // Date filter state

  const [filteredData, setFilteredData] = useState(data?.data?.data || []);

  useEffect(() => {
    let filteredOrders = data?.data?.data;

    if (selectedSalesman) {
      filteredOrders = filteredOrders.filter((order) => order.salesman === selectedSalesman.value);
    }

    if (selectedDate) {
      const dateObject = new Date(selectedDate);
      const offsetDate = new Date(dateObject.getTime() - (dateObject.getTimezoneOffset() * 60000)); // Adjust for timezone
      const selectedDateStr = offsetDate.toISOString().split('T')[0]; // Convert adjusted date to YYYY-MM-DD
      filteredOrders = filteredOrders.filter((order) => order.createdAt.startsWith(selectedDateStr));

    }

    setFilteredData(filteredOrders);
  }, [selectedSalesman, selectedDate, data]);

  const salesmanOptions = Array.from(new Set(data?.data?.data.map((order) => order.salesman))).map((salesman) => ({
    label: salesman,
    value: salesman,
  }));


  return (
    <>
      <Helmet>
        <title> Orders </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Orders
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            className="bg-primary"
            onClick={handleAddOrder}
            // href='/create-order'
          >
            Add Order
          </Button>
        </Stack>
        <Box mb={3} display="flex" gap={2}>
  <Box sx={{ width: '300px' }}>
    <Select
      options={salesmanOptions}
      placeholder="Select Salesman"
      onChange={setSelectedSalesman}
      isClearable
    />
  </Box>
  <Box sx={{ width: '200px' }}>
    <DatePicker
      label="Select Date"
      value={selectedDate}
      onChange={(newDate) => setSelectedDate(newDate)}
      renderInput={(params) => <TextField {...params} fullWidth />}
    />
  </Box>
</Box>

        <Card>
          <Scrollbar>
            {isValidating ? (
              <TableRow>
                <TableCell className="w-full">
                  <Typography align="center" sx={{ my: 2 }}>
                    Loading...
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <CommonTableHead headLabel={TABLE_HEAD} rowCount={data?.data?.total} enableSelect={enableSelect} />
                  <TableBody>
                    {isValidating ? (
                      <TableRow>
                        <TableCell className="w-full ">
                          <Typography align="center" sx={{ my: 2 }}>
                            Loading...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredData?.map((row, index) => {
                        const serialNumber = index + 1;
                        return (
                          <TableRow hover key={row?._id} tabIndex={-1} role="checkbox" selected={false}>
                            {enableSelect && (
                              <TableCell padding="normal">
                                <Checkbox checked={false} onChange={() => {}} />
                              </TableCell>
                            )}

                            {
                              <TableCell padding="normal" onClick={() => navigate(`/dashboard/order-view/${row?._id}`)}>
                                {' '}
                                {serialNumber}
                              </TableCell>
                            }
                            <TableCell
                              onClick={() => navigate(`/dashboard/order-view/${row?._id}`)}
                              component="th"
                              scope="row"
                              padding="normal"
                            >
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {row?.shopName ?? ''}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell
                              onClick={() => navigate(`/dashboard/order-view/${row?._id}`)}
                              component="th"
                              scope="row"
                              padding="normal"
                            >
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {row?.shopId ?? ''}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell
                              onClick={() => navigate(`/dashboard/order-view/${row?._id}`)}
                              component="th"
                              scope="row"
                              padding="normal"
                            >
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {row?.orderItems.length}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="right">
                              <Tooltip title={`edit ${row?.contactNumber}`}>
                                <IconButton size="large" color="inherit" onClick={() => handleEditOrder(row)}>
                                  <Iconify icon="eva:edit-fill" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={`Delete ${row?.contactNumber}`}>
                                <IconButton size="large" color="inherit" onClick={() => handleDeleteConfirm(row?._id)}>
                                  <Iconify icon="eva:trash-2-fill" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                  {(isError || (filteredData && filteredData?.length === 0)) && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                         
                            <Typography variant="h6" paragraph>
                              Not orders found
                            </Typography>

                           
                       
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            )}
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.data?.total}
            rowsPerPage={orderState.per_page}
            page={orderState.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <AlertModal
            show={showModal}
            continueText="Delete"
            dialogTitle="Confirm Delete"
            dialogContent="Are you sure you want to delete this item?"
            handleClose={handleModalClose}
            handleContinue={handleDelete}
          />
        </Card>
        <Dialog open={showAddOrder} onClose={closeAddOrderModal} maxWidth="md" fullWidth>
          <DialogTitle>{isUpdating ? 'Update Order' : 'Add Order'}</DialogTitle>
          <DialogContent>
            <Stack spacing={3}>
              <Autocomplete
                freeSolo
                options={filteredShops}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.shopName)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Shop Name"
                    fullWidth
                    error={formik.touched.shopName && Boolean(formik.errors.shopName)}
                    helperText={formik.touched.shopName && formik.errors.shopName}
                  />
                )}
                onInputChange={(event, newInputValue) => {
                  handleShopNameChange(event, newInputValue); // handle input change
                  formik.setFieldValue('shopName', newInputValue);
                }}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    formik.setFieldValue('shopName', newValue);
                  } else if (newValue && newValue.shopName) {
                    formik.setFieldValue('shopName', newValue.shopName);
                    handleShopSelect(newValue); // handle shop selection
                  }
                }}
              />
              <TextField
                name="shopId"
                label="Shop ID"
                fullWidth
                {...formik.getFieldProps('shopId')}
                error={formik.touched.shopId && Boolean(formik.errors.shopId)}
                helperText={formik.touched.shopId && formik.errors.shopId}
              />
              <TextField
                name="contactNumber"
                label="Contact Number"
                fullWidth
                {...formik.getFieldProps('contactNumber')}
                error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                helperText={formik.touched.contactNumber && formik.errors.contactNumber}
              />
              <TextField
                name="salesman"
                label="Sales Man"
                fullWidth
                {...formik.getFieldProps('salesman')}
                error={formik.touched.salesman && Boolean(formik.errors.salesman)}
                helperText={formik.touched.salesman && formik.errors.salesman}
              />
              <TextField
                name="vehicleNumber"
                label="vehicle Number"
                fullWidth
                {...formik.getFieldProps('vehicleNumber')}
                error={formik.touched.vehicleNumber && Boolean(formik.errors.vehicleNumber)}
                helperText={formik.touched.vehicleNumber && formik.errors.vehicleNumber}
              />

              <Typography variant="h5">Order Items</Typography>

              <TableContainer sx={{ minWidth: 600 }}>
                <Table>
                  <TableBody>
                    {formik.values.orderItems.map((row, index) => (
                      <TableRow hover key={index} tabIndex={-1} role="checkbox">
                        <TableCell>{index + 1}</TableCell>
                        <TableCell sx={{ minWidth: { xs: '80px', sm: '120px', md: '300px' } }}>
                          <Autocomplete
                            options={formattedItems}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} placeholder="Item" />}
                            value={
                              formattedItems.find((option) => option.value === formik.values.orderItems[index].id) ||
                              null
                            }
                            onChange={(event, newValue) => {
                              formik.setFieldValue(`orderItems[${index}].id`, newValue?.value || '');
                            }}
                            isOptionEqualToValue={(option, value) => option.value === value}
                          />
                        </TableCell>
                        <TableCell>
                          <Autocomplete
                            options={unitOptions}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} placeholder="Unit" />}
                            value={
                              unitOptions.find((option) => option.value === formik.values.orderItems[index].unit) ||
                              null
                            }
                            onChange={(event, newValue) => {
                              formik.setFieldValue(`orderItems[${index}].unit`, newValue?.value || '');
                            }}
                            isOptionEqualToValue={(option, value) => option.value === value}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            name={`orderItems[${index}].count`}
                            placeholder="Count"
                            type="number"
                            {...formik.getFieldProps(`orderItems[${index}].count`)}
                            error={
                              formik.touched.orderItems?.[index]?.count &&
                              Boolean(formik.errors.orderItems?.[index]?.count)
                            }
                            helperText={
                              formik.touched.orderItems?.[index]?.count && formik.errors.orderItems?.[index]?.count
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title={`Delete ${row.contactNumber}`}>
                            <IconButton size="large" color="inherit" onClick={() => removeFields(index)}>
                              <Iconify icon="eva:trash-2-fill" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                    {(isError || (data?.data?.data && data?.data?.data.length === 0)) && (
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          No results found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Button style={{ marginBlock: 20 }} variant="contained" onClick={addFields} className="bg-primary">
                Add More Item
              </Button>

              <LoadingButton
                loading={loading}
                size="large"
                type="submit"
                className="bg-primary !mt-auto"
                variant="contained"
                onClick={formik.handleSubmit}
              >
                {isUpdating ? 'Update' : 'Save'}
              </LoadingButton>
              <Button variant="outlined" onClick={closeAddOrderModal}>
                Close
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
};

export default OrdersPage;
