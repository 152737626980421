const { atom } = require('recoil');

export const ordersListState = atom({
  key: 'ordersListState',
  default: {
    searchValue: '',
    page: 0,
    per_page: 10,
  },
});
